.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    fill: #8e24aa !important;
    margin-left: -32px;
    margin-top: -32px;
    z-index: 99999;
}

.loading::before {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(245, 242, 242, 0.25);
    z-index: 1500;
    overflow: hidden;
}