.gridbox {
    grid-column: 1;
}

.gridbox-2 {
    grid-column: 2;
}

.griditem {
    width: 100%;
    height: 100%;

    display: grid !important;
    grid-template-columns: auto auto !important;
    column-gap: 10px;
}

.text-muted {
    color: rgb(237, 0, 0);
    font-weight: 100;
}

.inp-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.file-box-collections {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    border: 0.01px solid #767676;
    border-style: dashed;
    border-radius: 5px;
    height: calc(100% - 1.875rem);
}

.close-btn {
    background: white;
    box-shadow: 4px 4px 5px 5px rgba(194, 194, 194, 0.25);
    border: 0;
    border-radius: 9999px;
    padding: 5px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(75%, -50%);
}

.textFiled {
    width: '90%'
}

.table {
    /* border: 1px solid black; */
    border-radius: 5px;
    width: 100%;
    box-shadow: 2px 2px 15px 3px rgba(194, 194, 194, 0.25);
}

.header-cell {
    background-color: #5D87FF !important;
    color: white !important;
    font-size: 17px;
    font-weight: 100;
}

.table-button {
    top: 20px;
    right: 25px;
    position: absolute;
    /* padding: 2px; */
}

.table_grid {
    position: relative;
}

.button {
    background-color: #5D87FF;
    padding: 3px;
    border-radius: 9px;
    box-shadow: 2px 2px 15px 3px rgba(194, 194, 194, 0.25);
}

.del-button {
    background-color: #f75142;
    padding: 3px;
    border-radius: 9px;
    box-shadow: 2px 2px 15px 3px rgba(194, 194, 194, 0.25);
}

.icon {
    font-size: 10px;
}

.pagination {
    width: 100%;
    background-color: #f75142;
    float: right;
}

.btn {
    background-color: #5D87FF;
    /* padding: ; */
}

.text_pad .rdt_TableCell {
    padding: 10px;
}

.custom_btn {
    margin-top: 30px;
}

.btn-danger {
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}

.btn-success {
    background-color: #28a745 !important;
    border-color: #28a745 !important;
}

.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    fill: #8e24aa !important;
    margin-left: -32px;
    margin-top: -32px;
    z-index: 99999;
}

.loading::before {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(245, 242, 242, 0.25);
    z-index: 1500;
    overflow: hidden;
}